/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:wght@500&display=swap');

html, body { height: 100%; }
body {
    margin: 0; 
    /* font-family: 'Kiwi Maru', serif;
    font-family: 'Open Sans', sans-serif; */

    
    /* font-family: 'Gloria Hallelujah', cursive;
    font-family: 'Roboto', sans-serif; */

    font-family: 'Lato', sans-serif;
    font-family: 'Roboto', sans-serif;
   
}

snack-bar-container.mat-snack-bar-container {
    max-width: 70vw;
}
